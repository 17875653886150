/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-1",
    "aws_cognito_identity_pool_id": "eu-west-1:13ff126b-13ee-4e85-b982-1700f8be5352",
    "aws_cognito_region": "eu-west-1",
    aws_user_pools_id: 'eu-west-1_6IByPaRZ3',
    aws_user_pools_web_client_id: '7ouqu80hojhlv3rn88cmlrh233',
    oauth: {
        domain: 'gogreen-slovakia.auth.eu-west-1.amazoncognito.com',
        scope: ['email', 'profile', 'openid'],
        //redirectSignIn: 'http://localhost:3000/',
        redirectSignIn: 'https://dev.gogreenslovakia.com/',
        responseType: 'token',
    },
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_appsync_graphqlEndpoint": "https://lv6ybw5qqzbujcq3v6lgfhazny.appsync-api.eu-west-1.amazonaws.com/graphql",
    "aws_appsync_region": "eu-west-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_user_files_s3_bucket": "activity-images172519-dev",
    "aws_user_files_s3_bucket_region": "eu-west-1"
};


export default awsmobile;
